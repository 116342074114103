<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-card-body class="p-0">
              <!-- Komponen Biaya -->
              <b-form-group label="Komponen Biaya" label-for="cost-component">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Komponen Biaya"
                  rules="required"
                >
                  <b-form-select
                    id="cost-component"
                    v-model="model.costComponent"
                    :options="costComponentOptions"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- Keterangan -->
              <b-form-group label="Keterangan" label-for="note">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Keterangan"
                  rules="required"
                >
                  <b-form-input
                    id="note"
                    placeholder="Masukkan keterangan..."
                    v-model="model.note"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- Biaya -->
              <b-form-group label="Biaya" label-for="cost">
                <vue-numeric
                  id="cost"
                  v-model="model.cost"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Masukkan Biaya"
                  currency="Rp"
                  separator="."
                ></vue-numeric>
              </b-form-group>

              <!-- Upload Kwitansi -->
              <b-form-group label="Upload Kwitansi" label-for="inputKwitansi">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kwitansi"
                  :rules="metaAction === 'edit' ? '' : `required`"
                >
                  <b-form-file
                    id="inputKwitansi"
                    v-model="kwitansi"
                    :placeholder="placeholderFile"
                    drop-placeholder="Taruh file disini..."
                    accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                    @change="handleUploadKwitansi"
                  ></b-form-file>
                  <b-link
                    v-if="currentReceiptUrl"
                    :href="currentReceiptUrl"
                    target="_blank"
                    class="d-block text-info mt-1"
                  >
                    {{ currentReceiptUrl.split('/').at(-1) }}
                  </b-link>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-card-body>
            <b-card-footer align="right" class="px-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="goBack"
              >
                Kembali
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="loadingUpload"
                class="ml-1"
                variant="primary"
                type="submit"
              >
                Simpan
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  name: 'SppdRealExpenseForm',
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormFile,
  },
  directives: { Ripple },
  data() {
    const { sppdId, sppdPengeluaranRiilId } = this.$route.params
    const { action: metaAction } = this.$route.meta

    return {
      sppdId,
      sppdPengeluaranRiilId,
      metaAction,
      config: {
        api: '/real-expenses',
        // redirect: `/pengeluaran-rills/${sppdId}`,
        redirect: `/manajemen-sppd/${sppdId}/detail`,
      },
      model: {
        costComponent: null,
        note: '',
        cost: 0,
        receipt: '',
      },
      currentReceiptUrl: null,
      costComponentOptions: [{ value: null, text: 'Pilih Komponen Biaya' }],
      kwitansi: null,
      placeholderFile: 'Pilih file...',
      loadingUpload: false,
    }
  },
  mounted() {
    if (this.metaAction === 'edit') {
      this.getRealExpense()
    }

    this.getCostComponents()
  },
  methods: {
    getCostComponents() {
      axios.get('/misc/cost-components').then(res => {
        const options = res.data.data.map(({ id, name }) => ({
          value: id,
          text: name,
        }))
        this.costComponentOptions = [...this.costComponentOptions, ...options]
      })
    },
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    handleUploadKwitansi(event) {
      this.loadingUpload = true
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('folder', this.$route.query.folder)
      formData.append('type', 'pengeluaran riil')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.loadingUpload = false
          this.model.receipt = res.data.filename
        })
        .catch(() => {
          this.loadingUpload = false
          // console.error(err.response)
        })
    },
    save() {
      const { costComponent, note, cost, receipt } = this.model
      if (this.metaAction === 'store') {
        axios
          .post(`/sppd/${this.sppdId}/real-expense`, {
            cost_component_id: costComponent,
            note,
            total_cost: cost,
            receipt,
          })
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Pengeluaran riil berhasil ditambahkan',
              },
            })
          })
          .catch(() => {
            // console.error(e.message)
          })
      } else {
        axios
          .put(`${this.config.api}/${this.sppdPengeluaranRiilId}`, {
            cost_component_id: costComponent,
            note,
            total_cost: cost,
            receipt,
          })
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Pengeluaran riil berhasil diubah',
              },
            })
          })
          .catch(() => {
            // console.error(e.message)
          })
      }
    },
    goBack({ query }) {
      this.$router.push({
        path: this.config.redirect,
        query,
      })
    },
    getRealExpense() {
      axios
        .get(`${this.config.api}/${this.sppdPengeluaranRiilId}/detail`)
        .then(res => {
          const { data } = res.data
          const {
            total_cost: cost,
            cost_component_id: costComponent,
            note,
            receipt,
            file,
          } = data

          this.currentReceiptUrl = file

          this.model = {
            costComponent,
            note,
            cost,
            receipt: receipt.split('/').at(-1),
          }
        })
    },
  },
}
</script>

<style>
.kwitansi-filename {
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}
</style>
